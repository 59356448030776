import { Link } from "gatsby";
import * as React from "react";
import { FullWidth } from "../components/fullWidth/FullWidth";
import { Layout } from "../components/layout/Layout";

import "./404.scss";

const NotFoundPage = ({ location }) => {
  return (
    <Layout>
      <FullWidth innerClassName="error-404--root">
        <h1>Page Not Found</h1>
        {/* TODO update copy */}
        <p>We don't have a page for this URL, unfortunately.</p>
        <p>
          Please return to the{" "}
          <Link to="/" className="error-404--link">
            Home Page.
          </Link>
        </p>
      </FullWidth>
    </Layout>
  );
};

export default NotFoundPage;
